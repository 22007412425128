
import React, {Fragment, useEffect, useRef, useState} from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Chip, Divider, Grid, Link, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import VideocamIcon from '@mui/icons-material/Videocam';
import SchoolIcon from '@mui/icons-material/School';
import ChatIcon from '@mui/icons-material/Chat';

import { faBriefcase,faCalendarAlt, faCertificate, faClock, faExclamationCircle, faGlobe, faInfoCircle, faMapMarkerAlt, faPlayCircle, faSignal, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { COURSE_TYPE } from 'utils/constants/courses-constants';
import { GROUP } from 'utils/constants/back-office-constants';
import { universities } from 'utils/constants/constants';
import courseService from 'services/api/courseService';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import { useIntl } from 'gatsby-plugin-intl';


const useStyles = makeStyles((theme) => ({
    // icon: {
    //     color: theme.palette.primary.main,
    //     marginRight: theme.spacing(1),
    // },
    img: {
        // paddingTop: 20,
        marginRight: 70,
        float: "right",
        width: 100,
    },
}));

const CourseInfo = ({ courseId, sessionId, courseDetails, user }) => {
    const classes = useStyles();
    const intl = useIntl();

    let [image, setImage] = useState('');
    let [imageAlt, setImageAlt] = useState({});
    let [expanded, setExpanded] = useState('');
    
    let [showLinks, setShowLinks] = useState(false);
    let [links, setLinks] = useState([]);

    const itemEls = useRef({});

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const sortLinks = (links) => {

        const sortedLinks = [];

        for (const link of links) {

            const newLink = { ...link, order: 0, icon: null };
            switch (link.type) {
              case "WEBPAGE":
                newLink.order = 1;
                if (!link.description) {
                    newLink.description = intl.formatMessage({id: "lov.link.type.webpage"});
                }
                newLink.icon = <LanguageIcon fontSize="small" sx={{color: themeMuiV5.palette.primary.main}}/>;
                break;
              case "VIDEO":
                newLink.order = 2;
                if (!link.description) {
                    newLink.description = intl.formatMessage({id: "lov.link.type.video"});
                }
                newLink.icon = <VideocamIcon fontSize="small" sx={{color: themeMuiV5.palette.primary.main}}/>;
                break;
              case "SOCIAL":
                newLink.order = 3;
                newLink.description =  intl.formatMessage({id: "lov.link.type.social"});
                newLink.icon = <ChatIcon fontSize="small" sx={{color: themeMuiV5.palette.primary.main}}/>;
                break;
              case "LMS":
                newLink.order = 4;
                let lmsString = intl.formatMessage({id: "lov.link.type.lms"});
                if (!link.description) {
                    newLink.description = `(${lmsString})`;
                } else {
                    newLink.description += ` (${lmsString})`;
                }
                newLink.icon = <SchoolIcon fontSize="small" sx={{color: themeMuiV5.palette.primary.main}}/>;
                break;
            }
            sortedLinks.push(newLink);
        }
        sortedLinks.sort((a, b) => a.order - b.order);
        sortedLinks.sort((a, b) => {
            if (a.order === b.order && a.description && b.description) {
                return a.description.localeCompare(b.description);
            }
            return 0;
        });
        return sortedLinks;
    }
    
    

    const convertDate = (dateString) => {
        const localMoment = moment(dateString);
        return localMoment.format("DD/MM/YYYY") + ' ' + localMoment.toDate().toLocaleTimeString();
    };

    useEffect(()=>{
        if(courseDetails && user){
            if (courseDetails?.type == COURSE_TYPE.additional || courseDetails?.type == COURSE_TYPE.open || courseDetails?.type == COURSE_TYPE.skills) {
                setShowLinks(true);
            } else {
                let flatMap = user.cvcUser?.courses.flatMap(value => value.courseId);
                let b = flatMap?.includes(parseInt(courseId));
                setShowLinks(b);
            }
            if (!showLinks  && courseDetails?.links) {
                let sortedLinks = sortLinks(courseDetails.links);
                setLinks(sortedLinks);
            }
    
            let univ = universities.find(univ => univ.acronym === courseDetails?.universityAcronym);
            setImage(univ?.img ? univ.img : "");
            setImageAlt(univ?.name ? univ.name : "");
            
            if (!!sessionId) {
                let currentElement = itemEls.current[Number(sessionId)];
                if (currentElement) {
                    currentElement.scrollIntoView();
                    setExpanded('panel' + sessionId);
                }
            }
        }        
    }, []);
    
    return (
        courseId && courseDetails && (
            <Fragment>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container>
                            <Grid item xl={9} md={9} sm={8}>
                                <Typography variant="subtitle1">
                                    <Typography component={"span"}
                                        sx={{
                                            marginRight: themeMuiV5.spacing(1),
                                        }}>
                                        <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                            // className={classes.icon}
                                            color={themeMuiV5.palette.primary.main}
                                        />
                                    </Typography>
                                    {(courseDetails.multiJoinCode) ? courseDetails.multiJoinCode : ""}
                                    {(!!!courseDetails.multiJoinCode && courseDetails.code) ?
                                        courseDetails.universityName + ' - ' + courseDetails.code : ""}

                                    {(!!!courseDetails.multiJoinCode ?
                                        <img className={classes.img} src={image} alt={"logo " + imageAlt}/> : '')}

                                </Typography>
                                {/* <div className={classes.sectionDescription}>
                                <div className={classes.section2}> */}
                                <Typography component="div"
                                    sx={{
                                        marginRight: themeMuiV5.spacing(8),
                                    }}
                                >
                                    <Typography component="div"
                                        sx={{
                                            margin: themeMuiV5.spacing(2),
                                        }}
                                    >

                                        {courseDetails.title && (
                                            <Typography variant="h4" component="h1" gutterBottom>
                                                {courseDetails.title}
                                            </Typography>
                                        )}
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            align="justify"
                                            gutterBottom
                                            // className={classes.prewrap}
                                            sx= {{
                                                whiteSpace: "pre-line"
                                            }}
                                        >
                                            {courseDetails.shortDescription}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            align="justify"
                                            paragraph
                                            // className={classes.prewrap}
                                            sx={{
                                                whiteSpace: "pre-line"
                                            }}
                                        >
                                            {courseDetails.longDescription}
                                        </Typography>
                                    </Typography>
                                    {/* </div> */}
                                    {courseDetails["links"] && courseDetails["links"].length > 0 && showLinks &&
                                        <Typography component="div"
                                                sx={{
                                                    margin: themeMuiV5.spacing(2),
                                                }}
                                            >
                                                <Typography variant="subtitle1" component="h2" gutterBottom>
                                                {intl.formatMessage({id: "course.info.links"})}
                                                </Typography>
                                                {links.map((link, idx) => {
                                                    return (
                                                        <Typography
                                                            variant="body2"
                                                            key={`course-link-${idx}`}
                                                            sx={{
                                                                display: 'flex',
                                                                gap: '5px',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            {link.icon}
                                                            <Link href={link.url} target="_blank" rel="noreferrer">{link.description}</Link>

                                                        </Typography>
                                                    );
                                                })}
                                            </Typography>
                                    }

                                    {((courseDetails["topics"] && courseDetails["topics"].length > 0) || courseDetails.keywords) ?
                                        <Fragment>
                                            <Divider variant="middle"/>

                                            <Typography component="div"
                                                sx={{
                                                    margin: themeMuiV5.spacing(2),
                                                }}
                                            >
                                                {courseDetails["topics"] && courseDetails["topics"].length > 0 &&
                                                    <Fragment>
                                                        <Typography variant="subtitle1" component="h2" gutterBottom>
                                                        {intl.formatMessage({id: "course.topics"})}
                                                        </Typography>
                                                        {courseDetails["topics"] && courseDetails["topics"].length > 0 &&
                                                            courseDetails["topics"].map((value, idx) => {
                                                                return (
                                                                    <Chip
                                                                        key={`course-topics-${idx}`}
                                                                        label={intl.formatMessage({
                                                                            id: courseService.getLabelKeyByGroupAndCode(
                                                                                null,
                                                                                value.code
                                                                            ),
                                                                        })}
                                                                        sx={{
                                                                            fontWeight: 500
                                                                        }}
                                                                    />
                                                                );
                                                            })}
                                                    </Fragment>}
                                                    <Fragment>
                                                        {courseDetails.keywords && (
                                                            <Typography
                                                                variant="subtitle1"
                                                                component="h2"
                                                                gutterBottom
                                                            >
                                                            {intl.formatMessage({id: "course.keywords"})}
                                                            </Typography>
                                                        )}
                                                        {courseDetails.keywords && (
                                                            courseDetails.keywords.split(",")?.map((kw, idx) => <Chip
                                                                key={`course-keywords-${idx}`}
                                                                label={kw}
                                                                sx={{
                                                                    fontWeight: 500
                                                                }}
                                                            />)
                                                        )}
                                                    </Fragment>
                                            </Typography>

                                        </Fragment> : ''}
                                    {courseDetails["teachers"] && courseDetails["teachers"].length > 0 &&
                                        <Fragment>
                                        <Divider variant="middle"/>

                                            {courseDetails["teachers"] && courseDetails["teachers"].length > 0 && (
                                                <Typography component="div"
                                                    sx={{
                                                        margin: themeMuiV5.spacing(2),
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="h2"
                                                        gutterBottom
                                                    >
                                                        <Typography component={"span"}
                                                            sx={{
                                                                marginRight: themeMuiV5.spacing(1),
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={faUserAlt}
                                                                // className={classes.icon}
                                                                color={themeMuiV5.palette.primary.main}
                                                            />
                                                        </Typography>

                                                    {intl.formatMessage({id: "course.info.teachers"})}
                                                    </Typography>
                                                    {courseDetails["teachers"] && courseDetails["teachers"].length > 0 &&
                                                        courseDetails["teachers"].map((value, idx) => {
                                                            return (
                                                                <div key={`course-teacher-${idx}`}>
                                                                    <Typography 
                                                                        id={`course-teacher-names-${idx}`}
                                                                        variant="body1"
                                                                    >
                                                                        {value.title && (
                                                                            <>
                                                                                {intl.formatMessage({
                                                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                                                        GROUP.title,
                                                                                        value.title
                                                                                    ),
                                                                                })}{" : "}
                                                                            </>
                                                                        )}
                                                                        {value.firstname} {value.lastname} ({value.universityName})
                                                                    </Typography>
                                                                    <Typography
                                                                        id={`course-teacher-mail-${idx}`}
                                                                        variant="body1"
                                                                        style={{marginBottom: 10}}
                                                                    >
                                                                        {intl.formatMessage({
                                                                            id: "footer.navigation.contact",
                                                                        })}{" : "}
                                                                        <a href={'mailto:' + value.email}>
                                                                            {value.email}
                                                                        </a>
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })}
                                                </Typography>
                                            )}
                                        </Fragment>
                                    }

                                    {(courseDetails.helpDeskEmail ||
                                        courseDetails.academicEmail) && (
                                            <Fragment>
                                            <Divider variant="middle"/>
                                                <Typography component="div"
                                                    sx={{
                                                        margin: themeMuiV5.spacing(2),
                                                    }}
                                                >
                                                    {(courseDetails.helpDeskEmail && <Typography
                                                        variant="subtitle1"
                                                        key={`course-help-mail`}
                                                        gutterBottom
                                                    >
                                                        <Typography component={"span"}
                                                            sx={{
                                                                marginRight: themeMuiV5.spacing(1),
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                // className={classes.icon}
                                                                color={themeMuiV5.palette.primary.main}
                                                            />
                                                        </Typography>
                                                        {intl.formatMessage({
                                                            id: "course.help.desk.email",
                                                        })}{" : "}
                                                        <a href={'mailto:' + courseDetails.helpDeskEmail}>
                                                            {courseDetails.helpDeskEmail}
                                                        </a>
                                                    </Typography>)}
                                                    {(courseDetails.academicEmail && <Typography
                                                        variant="subtitle1"
                                                        key={`course-academic-mail`}
                                                        gutterBottom
                                                    >
                                                        <Typography component={"span"}
                                                            sx={{
                                                                marginRight: themeMuiV5.spacing(1),
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={faInfoCircle}
                                                                // className={classes.icon}
                                                                color={themeMuiV5.palette.primary.main}
                                                            />
                                                        </Typography>
                                                        {intl.formatMessage({
                                                            id: "course.academic.email",
                                                        })}{" : "}
                                                        <a href={'mailto:' + courseDetails.academicEmail}>
                                                            {courseDetails.academicEmail}
                                                        </a>
                                                    </Typography>)}
                                                </Typography>
                                            </Fragment>
                                        )}
                                </Typography>
                                {/* </div> */}
                            </Grid>
                            <Divider orientation="vertical" flexItem/>
                            <Grid item xl={2} md={2} sm={3}>
                                {/* <div className={classes.sectionInfo}> */}
                                <Typography component="div"
                                    sx={{
                                        marginLeft: themeMuiV5.spacing(2),
                                    }}
                                >

                                    {(courseDetails.type || courseDetails.subtype) ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faBriefcase}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {courseDetails.type &&
                                                intl.formatMessage({
                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                        GROUP.courseType,
                                                        courseDetails.type
                                                    ),
                                                })}{" "}
                                            {courseDetails.subType &&
                                                intl.formatMessage({
                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                        GROUP.subType,
                                                        courseDetails.subType
                                                    ),
                                                })}
                                        </Typography> : ''}

                                    {courseDetails.language ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faGlobe}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {courseDetails.language &&
                                                intl.formatMessage({
                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                        GROUP.language,
                                                        courseDetails.language
                                                    ),
                                                })}
                                        </Typography> : ''}
                                    {courseDetails.period ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faCalendarAlt}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {intl.formatMessage({
                                                id: courseService.getLabelKeyByGroupAndCode(
                                                    GROUP.period,
                                                    courseDetails.period
                                                ),
                                            })}
                                        </Typography> : ''}
                                    {courseDetails.firstSessionDate ?

                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faPlayCircle}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {intl.formatMessage({id: "course.first.session"})}
                                            : {new Date(courseDetails.firstSessionDate).toLocaleDateString(intl.locale)}
                                        </Typography> : ''}
                                    {courseDetails.nbSessions ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {intl.formatMessage({id: "course.sessions.count"})}
                                            : {courseDetails.nbSessions}
                                        </Typography> : ''}
                                    {courseDetails["cycles"] && courseDetails["cycles"].length > 0 ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faSignal}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {courseDetails["cycles"] && courseDetails["cycles"].length > 0 &&
                                                courseDetails["cycles"].map((value, idx) => {
                                                    let returnMsg = intl.formatMessage({ id: courseService.getLabelKeyByGroupAndCode(GROUP.courseCycle, value.code) });
                                                    if (idx !== courseDetails.cycles.length - 1) {
                                                        returnMsg += ', ';
                                                    }
                                                    return returnMsg;
                                                })}
                                        </Typography> : ''}
                                    {courseDetails.registrationDeadline ?

                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faExclamationCircle}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {intl.formatMessage({id: "course.deadline.registration"})}
                                            : {courseDetails.registrationDeadline}
                                        </Typography> : ''}


                                    {courseDetails.ectsCredit ?
                                        <Typography variant="body1" paragraph>
                                            <Typography component={"span"}
                                                sx={{
                                                    marginRight: themeMuiV5.spacing(1),
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={faCertificate}
                                                    // className={classes.icon}
                                                    color={themeMuiV5.palette.primary.main}
                                                />
                                            </Typography>
                                            {intl.formatMessage({id: "course.info.credits"})}{" "}
                                            {courseDetails.ectsCredit}
                                        </Typography> : ''}
                                    {/* </div> */}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                {(courseDetails["sessions"] && courseDetails["sessions"].length > 0) ?
                    <Card
                        // className={classes.section1}
                        sx={{
                            marginTop: themeMuiV5.spacing(2),
                        }}
                    >
                        <CardHeader
                            title={intl.formatMessage({ id: "course.info.sessions" })}
                            // className={classes.cardHeader}
                            sx={{
                                textAlign: "center",
                                backgroundColor: themeMuiV5.palette.primary.main,
                                color: themeMuiV5.palette.primary.contrastText
                            }}
                        />
                        <CardContent>
                            {
                                courseDetails["sessions"].map((session, idx) => {
                                    return (
                                        <Accordion defaultExpanded={idx === 0}
                                            expanded={ expanded === 'panel' + session.sessionId }
                                            onChange={handleChange('panel' + session.sessionId)}
                                            key={`session-${session.sessionId}`}
                                            ref={(element) => itemEls.current[session.sessionId] = element}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1bh-content"
                                                id={`panel-header-session-${session.sessionId}`}
                                                // className={classes.accordion}
                                                sx={{
                                                    backgroundColor: "#F0F0F0",
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontSize: themeMuiV5.typography.pxToRem(15),
                                                        flexBasis: '33.33%',
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    {session.title}
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        fontSize: themeMuiV5.typography.pxToRem(15),
                                                        color: themeMuiV5.palette.text.secondary,
                                                    }}
                                                >
                                                    <Typography component={"span"}
                                                        sx={{
                                                            marginRight: themeMuiV5.spacing(1),
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={faCalendarAlt}
                                                            // className={classes.icon}
                                                            color={themeMuiV5.palette.primary.main}
                                                        />
                                                    </Typography>
                                                    {convertDate(session.startDate)} -{" "}
                                                    {convertDate(session.endDate)}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>

                                                <Grid container item xl={9} md={9} sm={8}>
                                                    <Typography component="div"
                                                        sx={{
                                                            marginRight: themeMuiV5.spacing(8),
                                                        }}
                                                    >
                                                        <Typography component="div"
                                                            sx={{
                                                                margin: themeMuiV5.spacing(2),
                                                            }}
                                                        >
                                                            <Grid item xl={12} md={12} sm={12}>
                                                                <Typography
                                                                    variant="body1"
                                                                    component="div"
                                                                    align="justify"
                                                                    gutterBottom
                                                                    sx={{
                                                                        whiteSpace: "pre-line"
                                                                    }}
                                                                >
                                                                    {session.shortDescription}
                                                                </Typography>
                                                            </Grid>
                                                        </Typography>

                                                        <Grid item xl={12} md={12} sm={12}>
                                                            {session["teachers"] && session["teachers"].length > 0 &&
                                                                <Fragment>
                                                                <Divider variant="middle"/>

                                                                    {session["teachers"] && session["teachers"].length > 0 && (
                                                                        <Typography component="div"
                                                                            sx={{
                                                                                margin: themeMuiV5.spacing(2),
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                component="h2"
                                                                                gutterBottom
                                                                            >
                                                                                <Typography component={"span"}
                                                                                    sx={{
                                                                                        marginRight: themeMuiV5.spacing(1),
                                                                                    }}>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faUserAlt}
                                                                                        // className={classes.icon}
                                                                                        color={themeMuiV5.palette.primary.main}
                                                                                    />
                                                                                </Typography>
                                                                            {intl.formatMessage({id: "course.info.teachers"})}
                                                                            </Typography>
                                                                            {session["teachers"] && session["teachers"].length > 0 &&
                                                                                session["teachers"].map((value, idx) => {
                                                                                    return (
                                                                                        <div key={`div-course-teacher-${idx}`}>
                                                                                            <Typography
                                                                                                variant="body1"
                                                                                                key={`course-teacher-${idx}`}
                                                                                            >
                                                                                                {intl.formatMessage({
                                                                                                    id: courseService.getLabelKeyByGroupAndCode(
                                                                                                        GROUP.title,
                                                                                                        value.title
                                                                                                    ),
                                                                                                })}{" : "}
                                                                                                {value.firstname} {value.lastname?.toUpperCase()} (
                                                                                                {value.universityName}
                                                                                                )
                                                                                            </Typography>
                                                                                            {value.email &&
                                                                                                <Typography
                                                                                                    variant="body1"
                                                                                                    key={`course-teacher-mail-${idx}`}
                                                                                                    style={{marginBottom: 10}}
                                                                                                >
                                                                                                    {intl.formatMessage({
                                                                                                        id: "footer.navigation.contact",
                                                                                                    })}{" : "}
                                                                                                    <a
                                                                                                        href={'mailto:' + value.email}>
                                                                                                        {value.email}
                                                                                                    </a>
                                                                                                </Typography>
                                                                                            }

                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </Typography>
                                                                    )}
                                                                </Fragment>
                                                            }
                                                        </Grid>
                                                        <Grid item xl={12} md={12} sm={12}>
                                                            {session["links"] && session["links"].length > 0 &&  showLinks &&
                                                            <Fragment>
                                                                <Divider variant="middle"/>
                                                                    <Typography component="div"
                                                                        sx={{
                                                                            margin: themeMuiV5.spacing(2),
                                                                        }}
                                                                    >

                                                                    <Typography
                                                                        variant="subtitle1"
                                                                        component="h2"
                                                                        gutterBottom
                                                                    >
                                                                        {intl.formatMessage({id: "course.info.links"})}
                                                                    </Typography>
                                                                    {session["links"] && session["links"].length > 0 &&
                                                                        session["links"].map((link, idx) => {
                                                                            return (
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    key={`session-link-${idx}`}
                                                                                    paragraph
                                                                                >
                                                                                    <a target="_blank" href={link.url}>
                                                                                        {link.description}
                                                                                    </a>
                                                                                </Typography>
                                                                            );
                                                                        })}
                                                                </Typography>
                                                            </Fragment>}

                                                        </Grid>
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem/>
                                                <Grid item xl={2} md={2} sm={3}>
                                                    {/* <div className={classes.sectionInfo}> */}
                                                    <Typography component="div"
                                                        sx={{
                                                            marginLeft: themeMuiV5.spacing(2),
                                                        }}
                                                    >


                                                        {session.format &&
                                                            <Grid item xl={12} md={12} sm={12}>

                                                                <Typography variant="body1" paragraph>
                                                                    <Typography component={"span"}
                                                                        sx={{
                                                                            marginRight: themeMuiV5.spacing(1),
                                                                        }}>
                                                                        <FontAwesomeIcon
                                                                            icon={faBriefcase}
                                                                            // className={classes.icon}
                                                                            color={themeMuiV5.palette.primary.main}
                                                                        />
                                                                    </Typography>
                                                                    {intl.formatMessage({
                                                                        id: courseService.getLabelKeyByGroupAndCode(
                                                                            GROUP.sessionFormat,
                                                                            session.format
                                                                        ),
                                                                    })}
                                                                </Typography>
                                                            </Grid>}
                                                        {session.language &&
                                                            <Grid item xl={12} md={12} sm={12}>

                                                                <Typography variant="body1" paragraph>
                                                                    <Typography component={"span"}
                                                                        sx={{
                                                                            marginRight: themeMuiV5.spacing(1),
                                                                        }}>
                                                                        <FontAwesomeIcon
                                                                            icon={faGlobe}
                                                                            // className={classes.icon}
                                                                            color={themeMuiV5.palette.primary.main}
                                                                        />
                                                                    </Typography>
                                                                    {intl.formatMessage({
                                                                        id: courseService.getLabelKeyByGroupAndCode(
                                                                            GROUP.language,
                                                                            session.language
                                                                        ),
                                                                    })}
                                                                </Typography>
                                                            </Grid>
                                                        }
                                                    </Typography>
                                                    {/* </div> */}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                        </CardContent>
                    </Card> : ''}
            </Fragment>
        )
    );
};

export default CourseInfo;
